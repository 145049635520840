import { useEffect, useMemo, useRef, useState } from 'react';
import type { HeaderProps } from '@stitch-fix/knit';
import { useRouter } from 'next/router';
import { IconFixDelivery16 } from '@stitch-fix/mode-react';
import { useLocalStorageValue } from '@react-hookz/web';
import { kidsIncentiveBannerSupportedPaths } from './kidsIncentiveBannerSupportedPaths';
import {
  type BannerLocalStorageData,
  type IncentiveBannerTypes,
  knitBannersLocalStorageKey,
  type UseIncentiveBannerProps,
} from '../types';

export const KidsMultiFixDiscountCopy = {
  id: 'KidsMultiFixDiscountBanner-50usd',
  text: 'Our BIGGEST offer ever! $50 off: $25 off each of your first 2 Kids Fixes. Limited time only.',
  Icon: IconFixDelivery16,
};

export const useKidsIncentiveBanner = ({
  onClick,
  onLoad,
  isKidsMultifixEnabled,
  ffLoading,
}: UseIncentiveBannerProps): IncentiveBannerTypes => {
  const { asPath } = useRouter();
  const hasCalledOnLoad = useRef(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [closedBanners] = useLocalStorageValue<
    BannerLocalStorageData | undefined
  >(
    knitBannersLocalStorageKey,
    {},
    {
      initializeWithStorageValue: false,
    },
  );

  const { id, text, Icon } = KidsMultiFixDiscountCopy;

  const hasBannerBeenClosed = closedBanners?.[id]?.closed;

  const normalizedPath = asPath.split('?')[0];

  const shouldIncludeBanner = useMemo(() => {
    return kidsIncentiveBannerSupportedPaths.includes(normalizedPath);
  }, [normalizedPath]);

  const isBannerShown =
    shouldIncludeBanner && !hasBannerBeenClosed && hasLoaded;

  useEffect(() => {
    // We don't know which one to set until the Feature Flags are loaded
    // Don't execute the cookie logic until we know which one to set
    if (ffLoading) return;

    if (!hasCalledOnLoad.current) {
      hasCalledOnLoad.current = true;
      setHasLoaded(true);
      onLoad?.();
    }
  }, [
    onLoad,
    shouldIncludeBanner,
    isBannerShown,
    isKidsMultifixEnabled,
    ffLoading,
  ]);

  if (!isKidsMultifixEnabled || !isBannerShown || ffLoading) return {};

  const slimBanner: HeaderProps['slimBanner'] = {
    text,
    id,
    cta: {
      text: 'See details',
      onClick,
    },
    variant: 'citrus',
    icon: <Icon purpose="decorative" color="inherit" />,
  };

  return { slimBanner };
};
