import React from 'react';
import { Box, Button, Modal, Text } from '@stitch-fix/mode-react';
import { useClientAuth } from '@stitch-fix/sf-next';
import { AddAKidButton } from '../../AddAKidButton';
import { CloudinaryImageWrapper } from '../../CloudinaryImageWrapper';
import { ModalCampaign } from '../../../hooks/useIncentiveBanner/useIncentiveBanner';

import styles from './style.module.scss';

const modalVariant = () => {
  if (window?.location?.pathname?.match('/women')) return 'women';
  if (window?.location?.pathname?.match('/men')) return 'men';

  return 'default';
};

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  modalCampaign: ModalCampaign;
}

export const FrontDoorCreditCopy = {
  heading: 'Get $20 off + we’ll waive the styling fee on your first Fix',
  body: 'Get $20 off your first Fix and we’ll waive the $20 styling fee. Fill out your Style Profile and schedule your Fix to get started.',
  cta: 'GET MY CREDIT',
};

export const MultiFixDiscountCopy = {
  heading: 'Get $100 OFF — $25 off each of your first 4 Fixes',
  body: 'Must place first order within 7 days of sign up. Expires 1 year after first order. Kids Fixes receive $25 off first 2 Fixes and expire 6 months after first order. No subscription required. Credits applied at checkout. Credits will not apply to styling fees. Any remaining amount will not roll over.',
  cta: 'TAKE YOUR STYLE QUIZ',
};

export const KidsMultiFixDiscountCopy = {
  heading: 'Get $25 off each of your first 2 Fixes ($50 total)',
  body: 'Must place first order within 7 days of sign up. Expires 6 months after first order. No subscription or commitment required. $25 credits will be applied at checkout to your merchandise. Credits will not apply to styling fees. Any remaining amount will not roll over. Offer excludes Adult purchases.',
  cta: 'TAKE YOUR STYLE QUIZ',
};

const FD_IMAGE_VARIANTS = {
  men: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_M.png',
    alt: 'Stitch Fix men’s spring clothing including yellow pullover, rust and aqua colored shorts, folded khakis, and folded button down shirts.',
    width: 3342,
    height: 3342,
  },
  women: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_W.png',
    alt: 'Stitch Fix women’s spring clothing including multicolored maxi dress, pink skirt, v-neck black and white sweater and checkered skirt.',
    width: 3964,
    height: 3964,
  },
  default: {
    path: 'landing-pages/pages/US/BRND-FY24-WEB-Rebrand%20Client%20Experience%20Audit-1207669956273077/Modal%20Promo/Modal_Promo_GN.png',
    alt: 'Stitch Fix spring men’s and women’s clothing including blue jeans, sage colored dress, and button down short sleeve shirt.',
    width: 2000,
    height: 2000,
  },
};

const MF_IMAGE = {
  path: 'landing-pages/pages/US/gateway/Multi-Fix_PopUp_.png',
  alt: 'Variety of Stitch Fix clothing items arranged in a grid pattern',
  width: 2400,
  height: 2000,
};

const KIDS_MF_IMAGE = {
  path: 'landing-pages/pages/US/gateway/Multi-Fix_PopUp_Kids.jpg',
  alt: 'Variety of Stitch Fix kids clothing items arranged in a grid pattern',
  width: 1800,
  height: 1500,
};

export const SignupIncentiveModal = ({
  isOpen,
  close,
  modalCampaign,
}: ModalProps) => {
  const { authStatus } = useClientAuth();
  const loggedIn = authStatus === 'authenticated';

  if (modalCampaign == null) return null;

  let content = FrontDoorCreditCopy;
  // This is safe because this modal can only be rendered on the client
  let image = FD_IMAGE_VARIANTS[modalVariant()];

  if (modalCampaign === ModalCampaign.MULTI_FIX) {
    content = MultiFixDiscountCopy;
    image = MF_IMAGE;
  } else if (modalCampaign === ModalCampaign.KIDS_MULTI_FIX) {
    content = KidsMultiFixDiscountCopy;
    image = KIDS_MF_IMAGE;
  }

  const { heading, body, cta } = content;

  return (
    <Modal
      isOpen={isOpen}
      onClose={close}
      heading={heading}
      hero={
        <div className={styles.image}>
          <CloudinaryImageWrapper
            src={`https://res.cloudinary.com/stitch-fix/image/upload/${image.path}`}
            alt={image.alt}
            lazy
            objectFit="cover"
            sources={{
              sm: {
                w: 560,
                h: 560,
              },
            }}
            cloudinaryAsset={{
              path: image.path,
              width: image.width,
              height: image.height,
            }}
            maxContainerWidth={560}
            // height: undefined is needed to override the inline style that sets the height to auto
            imageProps={{ loading: 'lazy', style: { height: undefined } }}
          />
        </div>
      }
      variant="inset-small"
      hAlign="center"
      bodyLock="never"
    >
      <Text setting="body-large">{body}</Text>
      <Box maxWidth={18} my={0.5} mx="auto">
        {loggedIn && modalCampaign === ModalCampaign.KIDS_MULTI_FIX ? (
          <AddAKidButton />
        ) : (
          <Button
            as="a"
            href="/signup"
            variant="filled-staple"
            mt={1}
            data-testid="modal-cta"
            onClick={close}
          >
            {cta}
          </Button>
        )}
      </Box>
    </Modal>
  );
};
