import cookies from 'browser-cookies';

export const DEFAULT_CREDIT_AMOUNT = `20`;

export const getFormattedExpirationDate = () => {
  const expirationDate = new Date();

  // expiration date should be 30 days from current date
  expirationDate.setDate(expirationDate.getDate() + 30);
  const expirationDateYear = expirationDate.getFullYear();
  const expirationDateMonth = expirationDate.getMonth() + 1;
  const expirationDateDay = expirationDate.getDate();

  return `${expirationDateYear}-${expirationDateMonth}-${expirationDateDay}`;
};

export const getCreditIncentive = () => {
  return atob(cookies.get('signup_incentive') ?? '');
};

type CreditIncentiveArgs = {
  creditAmount?: string;
  expirationDate?: string | number | Date;
};

/**
 * Write cookie that gets picked up by onboarding flow
 * to show a special interstitial regarding credit.
 */
export const setCreditIncentive = ({
  creditAmount = DEFAULT_CREDIT_AMOUNT,
  expirationDate = getFormattedExpirationDate(),
}: CreditIncentiveArgs) => {
  cookies.set(
    'signup_incentive',
    btoa(`amount=${creditAmount}&expirationDate=${expirationDate}`),
    {
      expires: expirationDate,
    },
  );
};
