import React, { useState } from 'react';
import { useFeatureFlags } from '@stitch-fix/sf-next';
import { useAdultsIncentiveBanner } from './adults/useAdultsIncentiveBanner';
import { useKidsIncentiveBanner } from './kids/useKidsIncentiveBanner';
import { SignupIncentiveModal } from '../../components/Layout/SignupIncentiveBanner/SignupIncentiveModal';

// eslint-disable-next-line no-shadow
export enum ModalCampaign {
  FRONT_DOOR,
  MULTI_FIX,
  KIDS_MULTI_FIX,
}

export const useIncentiveBanner = () => {
  const [showBannerPlaceholder, setShowBannerPlaceholder] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const modalClose = () => {
    setShowModal(false);
  };
  const handleBannerClick = () => setShowModal(true);
  const handleBannerLoad = () => setShowBannerPlaceholder(false);

  const { featureFlags: visitorFeatureFlags, loading: ffLoading } =
    useFeatureFlags(
      'visitor',
      [
        'visitor.web.enable_multi_fix_discount',
        'visitor.web.enable_kids_multi_fix_discount',
      ],
      {
        skip: process.env.NODE_ENV === 'test',
      },
    );

  const isMultifixEnabled =
    visitorFeatureFlags?.['visitor.web.enable_multi_fix_discount'] ?? false;
  const { banner: adultBanner, slimBanner: adultSlimBanner } =
    useAdultsIncentiveBanner({
      onClick: handleBannerClick,
      onLoad: handleBannerLoad,
      isMultifixEnabled,
      ffLoading,
    });

  const isKidsMultifixEnabled =
    visitorFeatureFlags?.['visitor.web.enable_kids_multi_fix_discount'] ??
    false;
  const { banner: kidsBanner, slimBanner: kidsSlimBanner } =
    useKidsIncentiveBanner({
      onClick: handleBannerClick,
      onLoad: handleBannerLoad,
      isKidsMultifixEnabled,
      ffLoading,
    });

  let modalCampaign = ModalCampaign.FRONT_DOOR;

  // We ensure adult banners only show on adult pages and kids banners only show on kids pages
  // Therefore, we can use the banner existence to know which modal campaign to use
  if (isMultifixEnabled && (adultBanner || adultSlimBanner)) {
    modalCampaign = ModalCampaign.MULTI_FIX;
  } else if (isKidsMultifixEnabled && (kidsBanner || kidsSlimBanner)) {
    modalCampaign = ModalCampaign.KIDS_MULTI_FIX;
  }

  const modal = (
    <SignupIncentiveModal
      isOpen={showModal}
      close={modalClose}
      modalCampaign={modalCampaign}
    />
  );

  // We ensure adult banners only show on adult pages and kids banners only show on kids pages
  // Therefore, we can use this || logic in the banner and slimBanner return values
  return {
    showBannerPlaceholder,
    banner: adultBanner || kidsBanner,
    slimBanner: adultSlimBanner || kidsSlimBanner,
    modal,
  };
};
