import { useScreenViewEvent } from '@stitch-fix/event-reporter';
import { useClientI18n } from '@stitch-fix/sf-next';

export type LandingScreenViewTrackingProps = {
  name?: 'landing' | 'corporate' | 'signup' | 'login' | 'password_reset';
  children: React.ReactNode;
};

export const LandingScreenViewTracking = ({
  name = 'landing',
  children,
}: LandingScreenViewTrackingProps) => {
  const { locale, region } = useClientI18n();

  const { EventProvider } = useScreenViewEvent({
    schema: 'screen_view',
    name,
    locale,
    region,
    source_app: 'growth-next-ui',
  });

  return <EventProvider>{children}</EventProvider>;
};
