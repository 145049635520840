import type { AxiosError, AxiosResponse } from 'axios';
import { assign, createMachine, type DoneInvokeEvent } from 'xstate';
import { fireEvent } from '@stitch-fix/kufak-gtm-client';
import {
  createKidAccount,
  type CreateKidErrorResponse,
  type CreateKidSuccessResponse,
} from '../../utils/requests/clientAuth/createKidAccount';
import { browserLogger } from '../../utils/loggers/browser';

type AddAKidEvents = { type: 'ADD_A_KID_REQUEST' };

interface AddAKidContext {
  data?: {
    businessLine: string;
    externalId: string;
    kidId: number;
    kidExternalId: string;
    accessToken: string;
    refreshToken: string;
  };
}

const isErrorCode = (
  event: DoneInvokeEvent<AxiosError<CreateKidErrorResponse>>,
  errorCode: string,
) => event.data?.response?.data?.errors[0]?.code === errorCode;

export const addAKidMachine = createMachine<AddAKidContext, AddAKidEvents>(
  {
    /** @xstate-layout N4IgpgJg5mDOIC5QEMIQPrPQawJYQDp8AbMAYgEEARK9C9AaQElaAlAUQEUBVdgZQAqAbQAMAXUSgADgHtYuAC64ZAO0kgAHogC0AdgAsBXSN0BOUwA4AbAGYbFgEy7rAGhABPRAEZ9AVgIivg6WQXYW4TYAvpFuqBhYeITEMqi4KlBkEKpgRCoAbjLYOXGYOPgEyanpCGkFAMbISqqiYi3qsvJNakia3lZWBFbh+vq6NlbmVl6mDm6eCA4WIgTTDjZjDiL9DvpRMSAlCeWVEGkZYABOFzIXBFLEjQBmNwC2BIdlSSmn1bUyDV0Wm0eh1FMpuqAtAh1hYVrYrCIvF5fL4pmY5ogbCIbHCLKYbL5jNtdtFYmhSokKt8zmRLtdbvcnq93uSjl8qlAavl-o1wUCvBIQXIwap1FDxg4Anj7DtAmjTBjofiCJstvpzNZRiFSQdWZ8qRzaVcbncHgpnhc3h9KSczlz6rzmuIhA5BdJhV0xZirIYRNLHPo5V4lvpFerYX7Nr4vCIHDHkRYddbyo9kLhiABXC45DMqbAqGQAdxUlBodEYLHQHB4-GE4naHvBXqVphVQzWqNGMbWVkVPn8gWCFlC9gi0X2BYgcHUyYgDc6TZ6UO0TgGQV0SLjwT8O17Hh0Vn8phEx5s+iGJi8RKTespJDA85FEN6CBXiwI683Xm3QV8fb8ARBCEayjhYexkvE+q2ukj6ekuiBxjiqKHtiZ76MOFjnn2cYfkBw4geEYFeDekGUrAGZ1HUcDwEKC6ivBr4+K2PjDmhugOL4oxbIqDiHri+KElsVg7OBuqkSmaaZtmsGLpCiDCTiaoEpY9i2Ohir9AEx6mBMHGCViomzgQqbplmORTsgdRKHkjSQDJ9FyQgVgbgEPrKWB1hnhYPHOPxux2AiwbWCRFISaZ2YVLgLyKOwGhUZAdm0U+zYbl4Ky8cOKmeep+5KmlxhqhqPpmMOIVssZklmQQub5kWz6gnBjkzP4Xg2Is0ZLCGfh9r4SHaT6FhjDM7G+OOkRAA */
    id: 'add_a_kid',
    description: 'Add a kid to the clients account',
    predictableActionArguments: true,
    initial: 'idle',
    states: {
      idle: {
        on: {
          ADD_A_KID_REQUEST: {
            target: 'loading',
          },
        },
      },
      loading: {
        invoke: {
          src: async () => createKidAccount(),
          onDone: {
            target: 'success',
            actions: assign(
              (
                _context,
                event: DoneInvokeEvent<AxiosResponse<CreateKidSuccessResponse>>,
              ) => {
                const {
                  business_line,
                  external_id,
                  kid_id,
                  kid_external_id,
                  access_token,
                  refresh_token,
                } = event.data.data.success;

                return {
                  data: {
                    businessLine: business_line,
                    externalId: external_id,
                    kidId: kid_id,
                    kidExternalId: kid_external_id,
                    accessToken: access_token,
                    refreshToken: refresh_token,
                  },
                };
              },
            ),
          },
          onError: [
            {
              target: 'failure.deactivated',
              cond: (
                _context,
                event: DoneInvokeEvent<AxiosError<CreateKidErrorResponse>>,
              ) => isErrorCode(event, 'deactivated_client'),
            },
            {
              target: 'failure.limitExceeded',
              cond: (
                _context,
                event: DoneInvokeEvent<AxiosError<CreateKidErrorResponse>>,
              ) => isErrorCode(event, 'limit_exceeded'),
            },
            {
              target: 'failure.unknown',
            },
          ],
        },
      },
      success: {
        entry: ['logEvent', 'redirectToClient'],
      },
      failure: {
        states: {
          deactivated: {
            entry: ['redirectToInactive'],
          },
          limitExceeded: {},
          unknown: {
            entry: ['notifyError'],
            on: {
              ADD_A_KID_REQUEST: {
                target: '#add_a_kid.loading',
              },
            },
          },
        },
      },
    },
  },
  {
    actions: {
      logEvent: context => {
        if (context.data !== undefined) {
          fireEvent({
            eventName: 'add-a-kid',
            eventAttributes: {
              user: {
                businessLine: context.data.businessLine,
                external_id: context.data.externalId,
                kid_external_id: context.data.kidExternalId,
              },
            },
          });
        }
      },
      redirectToInactive: () => {
        window.location.assign('/account_inactive');
      },
      redirectToClient: () => {
        window.location.assign('/client');
      },
      notifyError: () => {
        browserLogger({
          level: 'error',
          team: 'first-touch',
          message: 'Error adding a kid',
          context: {
            error: new Error('Error adding a kid'),
          },
        });
      },
    },
  },
);
