import { type ImgHTMLAttributes } from 'react';
import {
  type BasicCloudinaryAsset,
  type SizeConfig,
  type MaxContainerWidth,
  getSrcSet,
  getSrc,
  getSizes,
} from './utils';

export type ContentStackCloudinaryAssetProps = {
  /**
   * The alt text for the image
   */
  alt: string;
  /**
   * The Cloudinary image's `width`, `height`, and `path`
   */
  cloudinaryAsset: BasicCloudinaryAsset;
  /**
   * Props passed to the `img` element, e.g., `{ loading: 'lazy' }`
   */
  imageProps?: Omit<
    ImgHTMLAttributes<HTMLImageElement>,
    'src' | 'srcset' | 'alt' | 'width' | 'height'
  >;
  /**
   * By default, the browser assumes an image will take up the _entire_ width of the viewport, i.e., `100vw`.
   * For page performance, use the `sizes` prop to match your CSS layout and tell the browser how much
   * of the viewport's width (in `vw`) the image will take up at every screen size (in `px`).
   *
   * Example: rendering an image in a CSS layout with 2 columns on small screens and 4 columns on screens >= 900px
   *
   * `sizes={[{ minWidth: 0, viewWidth: 50 }, { minWidth: 900, viewWidth: 25 }]}`
   */
  sizes?: SizeConfig[];

  /**
   * Max container width in pixels.
   *
   * Not all layouts are full bleed, they have maximum widths on desktop.
   * To avoid serving larger images than necessary, we can use the `maxContainerWidth`
   * in conjunction the view width (as a percentage) to calculate the maximum width of an image
   * in pixels.
   */
  maxContainerWidth?: MaxContainerWidth;

  /**
   * Pass `fetch` as the `cloudinaryAction` prop when rendering images
   * that are **not** hosted in Cloudinary, i.e., images hosted in S3.
   */
  cloudinaryAction?: 'upload' | 'fetch';
};

export const CloudinaryImageV2 = ({
  alt,
  cloudinaryAsset: cloudinaryField,
  imageProps = {},
  sizes,
  maxContainerWidth,
  cloudinaryAction,
}: ContentStackCloudinaryAssetProps) => {
  const { style, fetchPriority, ...imagePropsRest } = imageProps;

  return (
    <img
      alt={alt}
      src={getSrc({ cloudinaryAsset: cloudinaryField, cloudinaryAction })}
      srcSet={getSrcSet({ cloudinaryAsset: cloudinaryField, cloudinaryAction })}
      sizes={getSizes({
        sizes,
        // The browser decides which image to request based on the image width * screen resolution,
        // e.g., for an image displayed at full-width (100vw) on a 400px 3x screen, the browser would request a 1200px image
        // To prevent requesting **huge** images, we cap the image fidelity to 2x by telling the browser
        // that the image will be displayed at 2/3 its size on screens with >= 3x resolution
        // Source: https://stackoverflow.com/questions/69586765/responsive-images-with-srcset-sizes-media-queries-prevent-loading-huge-image/69637293#69637293
        dprs: [{ resolution: 3, reduction: 2 / 3 }],
        maxContainerWidth,
      })}
      width={cloudinaryField.width}
      height={cloudinaryField.height}
      style={{ width: '100%', height: 'auto', ...style }}
      // @ts-expect-error fetchpriority hasn't been added to the React types yet
      // see https://github.com/DefinitelyTyped/DefinitelyTyped/pull/63178 for more information
      // eslint-disable-next-line react/no-unknown-property
      fetchpriority={fetchPriority}
      {...imagePropsRest}
    />
  );
};
