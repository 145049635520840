import React, { useEffect, useState } from 'react';
import { Footer, Header } from '@stitch-fix/knit';
import { Box } from '@stitch-fix/mode-react';
import { useClientI18n } from '@stitch-fix/sf-next';
import { recordCustomTiming } from '@stitch-fix/client-analytics-reporter';
import { JsonLd } from '../JsonLd';
import { trackPageView } from '../../utils/events';
import { isIosApp } from '../../utils/helpers';
import {
  LandingScreenViewTracking,
  type LandingScreenViewTrackingProps,
} from '../LandingScreenViewTracking';
import { useIncentiveBanner } from '../../hooks/useIncentiveBanner/useIncentiveBanner';

export interface LayoutProps {
  forceHideHeaderFooter?: boolean;
  viewEventTrackingName?: LandingScreenViewTrackingProps['name'];
  children: React.ReactNode;
  footerRef?: React.RefCallback<HTMLDivElement>;
}

export const Layout = ({
  children,
  viewEventTrackingName,
  forceHideHeaderFooter = false,
  footerRef,
}: LayoutProps) => {
  const [isMounted, setIsMounted] = useState(false);
  const shouldRenderHeaderFooter =
    !forceHideHeaderFooter &&
    !isIosApp(isMounted ? window.navigator.userAgent : '');

  const { region } = useClientI18n();

  const { showBannerPlaceholder, banner, slimBanner, modal } =
    useIncentiveBanner();

  useEffect(() => {
    setIsMounted(true);
    trackPageView();

    recordCustomTiming('landingpages_metric_react_app_boot');

    return () => {
      setIsMounted(false);
    };
  }, []);

  return (
    <LandingScreenViewTracking name={viewEventTrackingName}>
      {shouldRenderHeaderFooter && (
        <Header region={region} banner={banner} slimBanner={slimBanner} />
      )}
      {isMounted && modal}
      {/* Hold open a spot for the banner to prevent shift */}
      {showBannerPlaceholder && <Box height="68px" />}
      {children}
      {shouldRenderHeaderFooter && (
        <Box ref={footerRef}>
          <Footer region={region} />
        </Box>
      )}
      <JsonLd />
    </LandingScreenViewTracking>
  );
};
