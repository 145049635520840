import { useEffect, useMemo, useRef, useState } from 'react';
import type { HeaderProps } from '@stitch-fix/knit';
import { useRouter } from 'next/router';
import { IconFixDelivery16, IconSuccess16 } from '@stitch-fix/mode-react';
import { useLocalStorageValue } from '@react-hookz/web';
import { adultsIncentiveBannerSupportedPaths } from './adultsIncentiveBannerSupportedPaths';
import { setCreditIncentive } from '../../../utils/creditIncentive';
import {
  type BannerLocalStorageData,
  type IncentiveBannerTypes,
  knitBannersLocalStorageKey,
  type UseIncentiveBannerProps,
} from '../types';

export const FrontDoorCreditCopy = {
  id: 'FrontdoorSignupCreditBanner-20usd',
  text: "Get $20 off + we'll waive the styling fee on your first Fix",
  Icon: IconSuccess16,
};
export const MultiFixDiscountCopy = {
  id: 'MultiFixDiscountBanner-100usd',
  text: 'Our BIGGEST offer ever! $100 off: $25 off each of your first 4 boxes. Limited time only.',
  Icon: IconFixDelivery16,
};

export const useAdultsIncentiveBanner = ({
  onClick,
  onLoad,
  isMultifixEnabled,
  ffLoading,
}: UseIncentiveBannerProps): IncentiveBannerTypes => {
  const { asPath } = useRouter();
  const hasCalledOnLoad = useRef(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const [closedBanners] = useLocalStorageValue<
    BannerLocalStorageData | undefined
  >(
    knitBannersLocalStorageKey,
    {},
    {
      initializeWithStorageValue: false,
    },
  );

  const { id, text, Icon } = isMultifixEnabled
    ? MultiFixDiscountCopy
    : FrontDoorCreditCopy;

  const hasBannerBeenClosed = closedBanners?.[id]?.closed;

  const normalizedPath = asPath.split('?')[0];

  const shouldIncludeBanner = useMemo(() => {
    return adultsIncentiveBannerSupportedPaths.includes(normalizedPath);
  }, [normalizedPath]);

  const isBannerShown =
    shouldIncludeBanner && !hasBannerBeenClosed && hasLoaded;

  useEffect(() => {
    // We don't know which one to set until the Feature Flags are loaded
    // Don't execute the cookie logic until we know which one to set
    if (ffLoading) return;

    if (!hasCalledOnLoad.current) {
      if (shouldIncludeBanner) {
        setCreditIncentive({ creditAmount: isMultifixEnabled ? '25' : '20' });
      }

      hasCalledOnLoad.current = true;
      setHasLoaded(true);
      onLoad?.();
    }
  }, [
    onLoad,
    shouldIncludeBanner,
    isBannerShown,
    isMultifixEnabled,
    ffLoading,
  ]);

  if (!isBannerShown || ffLoading) return {};

  const slimBanner: HeaderProps['slimBanner'] = {
    text,
    id,
    cta: {
      text: 'See details',
      onClick,
    },
    variant: isMultifixEnabled ? 'citrus' : 'promo-dark',
    icon: <Icon purpose="decorative" color="inherit" />,
  };

  return { slimBanner };
};
